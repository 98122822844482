import React from "react"

function Home(props) {
    return <div>
        <section id="producten">
            <div class="container-fluid">
                <div class="row setRow titleRow productRow">
                    <div class="col-lg-2 col-md-4">
                        <img class="pimg" src={props.img} alt="Afbeelding" />
                    </div>
                    <div class="col-lg-2 col-md-4"><p><strong>Productnaam</strong></p><p>{props.naam}</p></div>
                    <div class="col-lg-2 col-md-4"><p><strong>Categorie</strong></p><p>{props.categorie}</p></div>
                    <div class="col-lg-2 col-md-4"><p><strong>Geschatte Zoekvolume</strong></p><p>{props.zoekvolume} p/m</p></div>
                    <div class="col-lg-2 col-md-4"><p><strong>Geschatte Verkoopprijs</strong></p><p>€{props.prijs}</p></div>
                    <div class="col-lg-2 col-md-4"><p><strong>Link</strong></p><a href={props.url} class="btn btn-primary" rel="noreferrer" target="_blank">Bekijk op Bol</a></div>
                </div >
            </div >

        </section >
    </div>
};

export default Home;