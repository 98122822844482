
// import './styles.css';
// import MySideNav from "./components/MySideNav";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home"
import producten from "./producten";
import React from "react";
import Header from "./components/Header";

console.log(producten)

function createCard(product) {
  return (
    <Home
      key={product.id}
      naam={product.pnaam}
      img={product.afbeelding}
      categorie={product.categorie}
      zoekvolume={product.zoekvolume}
      prijs={product.prijs}
      url={product.url}
    />
  );
};

function App() {

  return (

    <div>
      <Header />
      <section id="title">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="page kop"> <h1 class="h1Title">101 Productideeën</h1>
<p>In deze lijst vind je 101 productideeën om je Bol.com business een vliegende start te geven.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>{producten.map(createCard)}</div>
      <div class="disclaimer">
        <p><span class="vet">Let op:</span> De lijst met 101 productideeën is gemaakt ter inspiratie.</p>
        <p>Er kunnen dan ook geen rechten aan ontleend worden.</p>
        <p>De productafbeeldingen in deze lijst worden alleen als voorbeeld en ter inspiratie weergegeven van de productnaam.</p>
      </div>
    </div>
  );
}

export default App;
