import React from "react"

function Header() {
    return <div className="myHeader">
        <a href="https://www.businessbaas.nl/"><img src="https://www.businessbaas.nl/wp-content/uploads/2023/02/Logo-wit-1.png" alt="logo" /></a>
        <a className="link" href="https://www.businessbaas.nl/4k">4K Traject</a>
    </div>

};

export default Header;